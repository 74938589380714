import { call, put } from 'redux-saga/effects'
import { fetchTeamActions, setTeamActions, updateTeamActionSuccess } from '@teamWorkspace/actions/actions'
import { getTeamWorkspaceApi } from '@common/net'
import { omit } from 'lodash'
import { createSaga } from '@common/sagaCreator/createSaga'
import { parseOwner } from '@shared/Grid/utils/ownerCellUtils'

export const getTeamActions = createSaga(function* getTeamActions({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamWorkspaceApi()
  const data = yield call(api.request('getTeamActions', { query: { teamId: payload } }))

  yield put(setTeamActions(data))
})

export const createTeamAction = createSaga(function* createTeamAction({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { data } = payload
  const { teamId } = data
  const api = yield getTeamWorkspaceApi()
  const { id } = yield call(
    api.request('createTeamAction', {
      query: { teamId },
      body: omit(data, ['id', 'isNew', 'teamId']),
    }),
  )

  yield put(updateTeamActionSuccess({ id: data.id, data: { ...data, id, isNew: false } }))
},
true)

export const updateTeamAction = createSaga(function* updateTeamAction({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(
    api.request('updateTeamAction', {
      query: { teamId, actionId: data.id },
      body: {
        ...omit(data, ['id', 'teamId', 'name', 'createdAt', 'updatedAt', 'isNew']),
        owner: parseOwner(data.owner),
      },
    }),
  )

  yield put(updateTeamActionSuccess({ id: data.id, data }))
})

export const deleteTeamAction = createSaga(function* deleteTeamAction({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(api.request('deleteTeamAction', { query: { teamId, actionId: data.id } }))

  yield put(fetchTeamActions(teamId))
})
