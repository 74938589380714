import React from 'react'
import { ImoIcon } from '@imo/imo-ui-toolkit'
import { DashboardRedirection } from '../DashboardRedirection'
import TermsAndConditions from '../../views/myImoClient/components/TermsAndConditions/TermsAndConditions'
import { synergyTypes } from '../../helpers/constants'
import { ORG_DESIGN_PHASES } from '../../views/shared/OrgDesignCommon/types'
import { RouteObject } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getManagementType } from '@root/src/domain/instanceConfig/selectors'
import { PathKey } from '@root/src/config/PathKey'

export const DeliverablesCrumbTitle = () => {
  const managementType = useSelector(getManagementType)
  return <>{managementType ?? 'IMO'} Deliverables</>
}

export const DeliverableTrackerCrumbTitle = () => {
  const managementType = useSelector(getManagementType)
  return <>{managementType === 'SMO' ? 'Separation' : 'Integration'} Team Deliverable Tracker</>
}

export const dashboardRouter: RouteObject[] = [
  {
    path: '/dashboard',
    lazy: async () => {
      const { DashboardRootLayout } = await import(
        /* webpackChunkName: "Dashboard" */ /* webpackPrefetch: true */ '../DashboardRoot'
      )
      return { element: <DashboardRootLayout /> }
    },
    handle: { crumb: { title: 'My Team', icon: <ImoIcon icon="my-team" size={16} /> } },
    children: [
      { path: '', element: <DashboardRedirection /> },
      { path: 'terms-conditions', element: <TermsAndConditions /> },
      {
        path: 'team/:teamId',
        lazy: async () => {
          const { MyTeamWithAccessControl } = await import(
            /* webpackChunkName: "Dashboard" */ '../../views/myImoClient/pages/MyTeamLayout/MyTeam'
          )
          return { element: <MyTeamWithAccessControl /> }
        },
        children: [
          {
            path: 'team-home',
            lazy: async () => {
              const { TeamHomePage } = await import(
                /* webpackChunkName: "Dashboard" */ '../../views/myImoClient/pages/MyTeamLayout/TeamHome/TeamHome'
              )
              return { element: <TeamHomePage /> }
            },
          },
          {
            path: 'team-project-analytics',
            lazy: async () => {
              const { ProjectAnalyticsNav } = await import(
                /* webpackChunkName: "Dashboard" */
                '../../views/myImoClient/pages/MyTeamLayout/ProjectAnalytics/ProjectAnalytics'
              )
              return { element: <ProjectAnalyticsNav /> }
            },
            handle: { crumb: { title: 'Project Analytics' } },
            children: [
              {
                path: 'overview',
                lazy: async () => {
                  const { Overview } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/ProjectAnalytics/Overview'
                  )
                  return { element: <Overview /> }
                },
              },
              {
                path: 'gantt',
                lazy: async () => {
                  const { ProjectGantt } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/ProjectAnalytics/Gantt'
                  )
                  return { element: <ProjectGantt /> }
                },
                handle: { crumb: { title: 'Gantt' } },
              },
              {
                path: 'burndown',
                lazy: async () => {
                  const { Burndown } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/ProjectAnalytics/Burndown'
                  )
                  return { element: <Burndown /> }
                },
                handle: { crumb: { title: 'Burndown' } },
              },
            ],
          },
          {
            path: 'tsa-master-list',
            handle: { crumb: { title: 'TSA Master List' } },
            lazy: async () => {
              const { MasterListTSAWithAccessControl } = await import(
                /* webpackChunkName: "Dashboard" */
                '../../views/myImoClient/pages/TSAv2/MyTeamTSA/MasterListTSA'
              )
              return { element: <MasterListTSAWithAccessControl /> }
            },
          },
          {
            path: 'team-workspace',
            handle: { crumb: { title: 'Team Workspace' } },
            children: [
              {
                path: '',
                handle: { pathKey: PathKey.TeamWorkspace },
                lazy: async () => {
                  const { MyTeamTeamWorkspace } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/TeamWorkspace/TeamWorkspace'
                  )
                  return { element: <MyTeamTeamWorkspace /> }
                },
              },
              {
                path: 'decision-log',
                lazy: async () => {
                  const { DecisionLog } = await import(
                    /* webpackChunkName: "Dashboard" */ '../../views/myImoClient/pages/MyTeamLayout/DecisionLog'
                  )
                  return { element: <DecisionLog /> }
                },
                handle: { crumb: { title: 'Decision Log' } },
              },
              {
                path: 'risk-log',
                lazy: async () => {
                  const { RiskLog } = await import(
                    /* webpackChunkName: "Dashboard" */ '../../views/myImoClient/pages/MyTeamLayout/RiskLog'
                  )
                  return { element: <RiskLog /> }
                },
                handle: { crumb: { title: 'Risk Log' } },
              },
            ],
          },
          {
            path: 'team-status-update',
            lazy: async () => {
              const { TeamWeeklyStatusUpdate } = await import(
                /* webpackChunkName: "Dashboard" */
                '../../views/myImoClient/pages/MyTeamLayout/TeamStatusUpdate/TeamStatusUpdate'
              )
              return { element: <TeamWeeklyStatusUpdate /> }
            },
            handle: { crumb: { title: 'Weekly Status Update' }, pathKey: PathKey.WeeklyStatusUpdate },
          },
          {
            path: 'team-hypercare-log',
            lazy: async () => {
              const { HypercareLog } = await import(
                /* webpackChunkName: "Dashboard" */ '../../views/myImoClient/pages/MyTeamLayout/HypercareLog'
              )
              return { element: <HypercareLog /> }
            },
            handle: { crumb: { title: 'Day 1 Hypercare' } },
          },
          {
            path: 'team-value-capture',
            lazy: async () => {
              const { TeamValueCaptureV2Layout } = await import(
                /* webpackChunkName: "Dashboard" */
                '../../views/myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/TeamValueCaptureV2'
              )
              return { element: <TeamValueCaptureV2Layout /> }
            },
            handle: {
              crumb: {
                title: 'Team Value Capture',
              },
            },
            children: [
              {
                path: 'initiative-list',
                lazy: async () => {
                  const { InitiativeListNav } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/InitiativeList'
                  )
                  return { element: <InitiativeListNav /> }
                },
                children: [
                  {
                    path: 'cost',
                    lazy: async () => {
                      const { InitiativeTab } = await import(
                        /* webpackChunkName: "Dashboard" */
                        '../../views/myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/InitiativeList'
                      )
                      return { element: <InitiativeTab synergyType={synergyTypes.COST} /> }
                    },
                  },
                  {
                    path: 'revenue',
                    lazy: async () => {
                      const { InitiativeTab } = await import(
                        /* webpackChunkName: "Dashboard" */
                        '../../views/myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/InitiativeList'
                      )
                      return { element: <InitiativeTab synergyType={synergyTypes.REVENUE} /> }
                    },
                    handle: {
                      crumb: {
                        title: 'Revenue',
                      },
                    },
                  },
                  {
                    path: 'nwc',
                    lazy: async () => {
                      const { InitiativeTab } = await import(
                        /* webpackChunkName: "Dashboard" */
                        '../../views/myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/InitiativeList'
                      )
                      return { element: <InitiativeTab synergyType={synergyTypes.NWC} /> }
                    },
                    handle: {
                      crumb: {
                        title: 'NWC/Capex',
                      },
                    },
                  },
                  {
                    path: 'otc',
                    lazy: async () => {
                      const { InitiativeTab } = await import(
                        /* webpackChunkName: "Dashboard" */
                        '../../views/myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/InitiativeList'
                      )
                      return { element: <InitiativeTab synergyType={synergyTypes.ONE_TIME_COST} /> }
                    },
                    handle: {
                      crumb: {
                        title: 'One-time Cost',
                      },
                    },
                  },
                ],
              },
              {
                path: 'status-update',
                lazy: async () => {
                  const { StatusUpdateNavigation } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/StatusUpdateTab/StatusUpdateTab'
                  )
                  return { element: <StatusUpdateNavigation /> }
                },
                handle: {
                  crumb: {
                    title: 'Status Update',
                  },
                },
                children: [
                  {
                    path: 'overview',
                    lazy: async () => {
                      const { TeamVCStatusUpdateOverview } = await import(
                        /* webpackChunkName: "Dashboard" */
                        '../../views/myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/TeamValueCaptureV2'
                      )
                      return { element: <TeamVCStatusUpdateOverview /> }
                    },
                  },
                  {
                    path: 'month-by-month',
                    lazy: async () => {
                      const { TeamVCMonthByMonth } = await import(
                        /* webpackChunkName: "Dashboard" */
                        '../../views/myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/TeamValueCaptureV2'
                      )
                      return { element: <TeamVCMonthByMonth /> }
                    },
                    handle: {
                      crumb: {
                        title: 'Month-by-month',
                      },
                    },
                  },
                  {
                    path: 'implementation-details',
                    lazy: async () => {
                      const { ImplementationDetails } = await import(
                        /* webpackChunkName: "Dashboard" */
                        '../../views/myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/ImplementationDetails/ImplementationDetails'
                      )
                      return { element: <ImplementationDetails /> }
                    },
                    handle: {
                      crumb: {
                        title: 'Implementation Details',
                      },
                    },
                  },
                ],
              },
              {
                path: 'project-map',
                lazy: async () => {
                  const { ProjectMap } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/ProjectMap'
                  )
                  return { element: <ProjectMap /> }
                },
                handle: {
                  crumb: {
                    title: 'Project Map',
                  },
                },
              },
            ],
          },
          {
            path: 'team-org-design',
            lazy: async () => {
              const { TeamOrgDesignNav } = await import(
                /* webpackChunkName: "Dashboard" */
                '../../views/myImoClient/pages/MyTeamLayout/TeamOrgDesign/TeamOrgDesign'
              )
              return { element: <TeamOrgDesignNav /> }
            },
            handle: {
              crumb: {
                title: 'Team Org Design',
              },
            },
            children: [
              {
                path: 'dashboard',
                lazy: async () => {
                  const { TODDashboard } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/TeamOrgDesign/Dashboard/Dashboard'
                  )
                  return { element: <TODDashboard /> }
                },
              },
              {
                path: 'overview',
                lazy: async () => {
                  const { TODOverview } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/TeamOrgDesign/Overview/Overview'
                  )
                  return { element: <TODOverview /> }
                },
                handle: {
                  crumb: {
                    title: 'Overview',
                  },
                },
              },
              {
                path: 'layers',
                lazy: async () => {
                  const { LayersNav } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/TeamOrgDesign/Layers/Layers'
                  )
                  return { element: <LayersNav /> }
                },
                handle: {
                  crumb: {
                    title: 'Layers',
                  },
                },
                children: [
                  {
                    path: 'design',
                    lazy: async () => {
                      const { TODLayerTab } = await import(
                        /* webpackChunkName: "Dashboard" */
                        '../../views/myImoClient/pages/MyTeamLayout/TeamOrgDesign/Layers/Layers'
                      )
                      return { element: <TODLayerTab phase={ORG_DESIGN_PHASES.Design} /> }
                    },
                  },
                  {
                    path: 'selection',
                    lazy: async () => {
                      const { TODLayerTab } = await import(
                        /* webpackChunkName: "Dashboard" */
                        '../../views/myImoClient/pages/MyTeamLayout/TeamOrgDesign/Layers/Layers'
                      )
                      return { element: <TODLayerTab phase={ORG_DESIGN_PHASES.Selection} /> }
                    },
                    handle: {
                      crumb: {
                        title: 'Selection',
                      },
                    },
                  },
                  {
                    path: 'implementation',
                    lazy: async () => {
                      const { TODLayerTab } = await import(
                        /* webpackChunkName: "Dashboard" */
                        '../../views/myImoClient/pages/MyTeamLayout/TeamOrgDesign/Layers/Layers'
                      )
                      return { element: <TODLayerTab phase={ORG_DESIGN_PHASES.Implementation} /> }
                    },
                    handle: {
                      crumb: {
                        title: 'Implementation',
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'knowledge-center',
            lazy: async () => {
              const { KnowledgeCenterWithAccessControl } = await import(
                /* webpackChunkName: "Dashboard" */
                '../../views/myImoClient/pages/MyTeamLayout/KnowledgeCenter/KnowledgeCenter'
              )
              return { element: <KnowledgeCenterWithAccessControl /> }
            },
            handle: {
              crumb: {
                title: 'Knowledge Center',
              },
            },
            children: [
              {
                path: 'article/:articleId/pdf',
                lazy: async () => {
                  const { PDFViewer } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/components/KnowledgeCenter/Articles/PDFViewer'
                  )
                  return { element: <PDFViewer /> }
                },
              },
              {
                path: 'import-video',
                lazy: async () => {
                  const { KCImportVideo } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/KnowledgeCenter/KnowledgeCenterPages'
                  )
                  return { element: <KCImportVideo /> }
                },
              },
              {
                path: 'import-article',
                lazy: async () => {
                  const { KCImportArticle } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/KnowledgeCenter/KnowledgeCenterPages'
                  )
                  return { element: <KCImportArticle /> }
                },
              },
              {
                path: 'import-introduction-materials',
                lazy: async () => {
                  const { KCImportIntroductionMaterial } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/KnowledgeCenter/KnowledgeCenterPages'
                  )
                  return { element: <KCImportIntroductionMaterial /> }
                },
              },
              {
                path: 'hear-from-expert/modal',
                lazy: async () => {
                  const { KCExpertVideos } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/KnowledgeCenter/KnowledgeCenterPages'
                  )
                  return { element: <KCExpertVideos /> }
                },
              },
              {
                path: 'read-more/modal',
                lazy: async () => {
                  const { KCArticles } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/KnowledgeCenter/KnowledgeCenterPages'
                  )
                  return { element: <KCArticles /> }
                },
              },
              {
                path: 'introduction-materials/modal',
                lazy: async () => {
                  const { KCIntroductionMaterials } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/pages/MyTeamLayout/KnowledgeCenter/KnowledgeCenterPages'
                  )
                  return { element: <KCIntroductionMaterials /> }
                },
              },
            ],
          },
          {
            path: 'deliverables',
            handle: { crumb: { title: <DeliverablesCrumbTitle /> } },
            children: [
              {
                path: '',
                lazy: async () => {
                  const { DeliverablesOverview } = await import(
                    /* webpackChunkName: "Dashboard" */
                    '../../views/myImoClient/components/TeamHome/DeliverablesOverview'
                  )
                  return { element: <DeliverablesOverview /> }
                },
              },
              {
                path: ':deliverableId',
                lazy: async () => {
                  const { DeliverablesWrapper } = await import(
                    /* webpackChunkName: "Dashboard" */ '../../views/myImoClient/pages/Deliverable/Deliverable'
                  )
                  return { element: <DeliverablesWrapper /> }
                },
              },
            ],
          },
          {
            path: 'integration-team-deliverable-tracker',
            lazy: async () => {
              const { IntegrationTeamDeliverableTracker } = await import(
                /* webpackChunkName: "Dashboard" */
                '../../views/myImoClient/components/TeamHome/IntegrationTeamDeliverableTracker/IntegrationTeamDeliverableTracker'
              )
              return { element: <IntegrationTeamDeliverableTracker /> }
            },
            handle: { crumb: { title: <DeliverableTrackerCrumbTitle /> } },
          },
        ],
      },
    ],
  },
]
