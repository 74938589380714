import { ImoIcon } from '@imo/imo-ui-toolkit'
import { PathKey } from '@root/src/config/PathKey'
import { getManagementType } from '@root/src/domain/instanceConfig/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { RouteObject } from 'react-router-dom'
import { ControlTowerWithAccessControl } from '../../views/myImoClient/pages/ImoControlTower'
import { ControlTowerRoot } from '../../views/myImoClient/pages/ImoControlTower/ControlTowerRoot'
import { AccessControl } from '../../views/shared/AccessControl/AccessControl'
import { ControlTowerWrapper } from '../ImoClient'

export const ControlTowerCrumbTitle = () => {
  const managementType = useSelector(getManagementType)
  return <>{managementType ?? 'IMO'} Control Tower</>
}

export const DeliverableTrackerCrumbTitle = () => {
  const managementType = useSelector(getManagementType)
  return <>{managementType ?? 'IMO'} Deliverable Tracker</>
}

export const controlTowerRouter: RouteObject[] = [
  {
    path: '/imo-control-tower',
    element: <ControlTowerWrapper />,
    handle: { crumb: { title: <ControlTowerCrumbTitle />, icon: <ImoIcon icon="control-tower" size={16} /> } },
    children: [
      { path: '', element: <AccessControl component={ControlTowerRoot} /> },
      {
        path: 'team/:teamId',
        element: <ControlTowerWithAccessControl />,
        children: [
          {
            path: 'imo-home',
            lazy: async () => {
              const { ImoHome } = await import(
                /* webpackChunkName: "IMO Control Tower" */ /* webpackPrefetch: true */ '../../views/myImoClient/pages/ImoControlTower/ImoHome'
              )
              return { element: <ImoHome /> }
            },
          },
          {
            path: 'control-panel',
            lazy: async () => {
              const { ControlPanel } = await import(
                /* webpackChunkName: "IMO Control Tower" */ '../../views/myImoClient/pages/ImoControlTower/ControlPanel'
              )
              return { element: <ControlPanel /> }
            },
            handle: { crumb: { title: 'Control Panel' } },
          },
          {
            path: 'project-analytics',
            lazy: async () => {
              const { ProjectAnalyticsNav } = await import(
                /* webpackChunkName: "IMO Control Tower" */
                '../../views/myImoClient/pages/ImoControlTower/ProjectAnalytics'
              )
              return { element: <ProjectAnalyticsNav /> }
            },
            handle: { crumb: { title: 'Project Analytics' } },
            children: [
              {
                path: 'overview',
                lazy: async () => {
                  const { Overview } = await import(
                    /* webpackChunkName: "IMO Control Tower" */
                    '../../views/myImoClient/pages/ImoControlTower/ProjectAnalytics/Overview'
                  )
                  return { element: <Overview /> }
                },
              },
              {
                path: 'gantt',
                lazy: async () => {
                  const { ProjectGantt } = await import(
                    /* webpackChunkName: "IMO Control Tower" */
                    '../../views/myImoClient/pages/ImoControlTower/ProjectAnalytics/Gantt'
                  )
                  return { element: <ProjectGantt /> }
                },
                handle: { crumb: { title: 'Gantt' } },
              },
              {
                path: 'burndown',
                lazy: async () => {
                  const { Burndown } = await import(
                    /* webpackChunkName: "IMO Control Tower" */
                    '../../views/myImoClient/pages/ImoControlTower/ProjectAnalytics/Burndown'
                  )
                  return { element: <Burndown /> }
                },
                handle: { crumb: { title: 'Burndown' } },
              },
            ],
          },
          {
            path: 'all-projects',
            lazy: async () => {
              const { ProgramProjectPlan } = await import(
                /* webpackChunkName: "IMO Control Tower" */
                '../../views/myImoClient/pages/ImoControlTower/ProgramProjectPlan/ProgramProjectPlan'
              )
              return { element: <ProgramProjectPlan /> }
            },
            handle: { crumb: { title: 'All Projects' } },
          },
          {
            path: 'all-tsas',
            lazy: async () => {
              const { AllTSAs } = await import(
                /* webpackChunkName: "IMO Control Tower" */ '../../views/myImoClient/pages/TSAv2/AllTSA/AllTSAs'
              )
              return { element: <AllTSAs /> }
            },
            handle: { crumb: { title: 'All TSAs' } },
          },
          {
            path: 'weekly-status',
            lazy: async () => {
              const { WeeklyStatusWithAccessControl } = await import(
                /* webpackChunkName: "IMO Control Tower" */
                '../../views/myImoClient/pages/ImoControlTower/WeeklyStatus'
              )
              return { element: <WeeklyStatusWithAccessControl /> }
            },
            handle: { crumb: { title: 'Weekly Status' } },
            children: [
              {
                path: 'team-tracker',
                lazy: async () => {
                  const { SummaryTracker } = await import(
                    /* webpackChunkName: "IMO Control Tower" */
                    '../../views/myImoClient/pages/ImoControlTower/SummaryTracker/SummaryTracker'
                  )
                  return { element: <SummaryTracker /> }
                },
              },
              {
                path: 'team-update',
                lazy: async () => {
                  const { ImoTeamStatusUpdate } = await import(
                    /* webpackChunkName: "IMO Control Tower" */
                    '../../views/myImoClient/components/TeamStatusUpdate/ImoTeamStatusUpdate'
                  )
                  return { element: <ImoTeamStatusUpdate /> }
                },
                handle: { crumb: { title: 'Status Update' } },
              },
            ],
          },
          {
            path: 'steer-co-roadmap',
            lazy: async () => {
              const { SteerCoRoadmap } = await import(
                /* webpackChunkName: "IMO Control Tower" */
                '../../views/myImoClient/pages/ImoControlTower/SteerCoRoadmap/SteerCoRoadmap'
              )
              return { element: <SteerCoRoadmap /> }
            },
            handle: { crumb: { title: 'SteerCo Roadmap' } },
          },
          {
            path: 'team-hypercare',
            lazy: async () => {
              const { HypercareNav } = await import(
                /* webpackChunkName: "IMO Control Tower" */
                '../../views/myImoClient/pages/ImoControlTower/HypercareRouting/HypercareRouting'
              )
              return { element: <HypercareNav /> }
            },
            handle: { crumb: { title: 'Day 1 Hypercare' } },
            children: [
              {
                path: 'log',
                lazy: async () => {
                  const { HypercareLogContainer } = await import(
                    /* webpackChunkName: "IMO Control Tower" */
                    '../../views/myImoClient/pages/ImoControlTower/HypercareRouting/HypercareLogContainer'
                  )
                  return { element: <HypercareLogContainer /> }
                },
              },
              {
                path: 'day1-issue-kpis',
                lazy: async () => {
                  const { Day1IssueKPIs } = await import(
                    /* webpackChunkName: "IMO Control Tower" */
                    '../../views/myImoClient/pages/ImoControlTower/HypercareRouting/Day1IssuesKPIs/Day1IssueKPIs'
                  )
                  return { element: <Day1IssueKPIs /> }
                },
                handle: { crumb: { title: 'Issue KPIs' } },
              },
            ],
          },
          {
            path: 'imo-deliverable-tracker',
            lazy: async () => {
              const { ImoDeliverableTracker } = await import(
                /* webpackChunkName: "IMO Control Tower" */
                '../../views/myImoClient/pages/ImoControlTower/ImoHome/ImoDeliverablesTracker'
              )
              return { element: <ImoDeliverableTracker /> }
            },
            handle: { crumb: { title: <DeliverableTrackerCrumbTitle /> } },
          },
          {
            path: 'team-workspace',
            handle: { crumb: { title: 'Team Workspace' } },
            children: [
              {
                path: '',
                handle: { pathKey: PathKey.ControlTowerTeamWorkspace },
                lazy: async () => {
                  const { IMOControlTowerTeamWorkspace } = await import(
                    /* webpackChunkName: "IMO Control Tower" */
                    '../../views/myImoClient/pages/ImoControlTower/TeamWorkspace/TeamWorkspace'
                  )
                  return { element: <IMOControlTowerTeamWorkspace /> }
                },
              },
              {
                path: 'risk-log',
                lazy: async () => {
                  const { RiskLog } = await import(
                    /* webpackChunkName: "IMO Control Tower" */ '../../views/myImoClient/pages/ImoControlTower/RiskLog'
                  )
                  return { element: <RiskLog /> }
                },
                handle: { crumb: { title: 'Risk Log' } },
              },
              {
                path: 'decision-log',
                lazy: async () => {
                  const { DecisionLog } = await import(
                    /* webpackChunkName: "IMO Control Tower" */ '../../views/myImoClient/pages/ImoControlTower/DecisionLog'
                  )
                  return { element: <DecisionLog /> }
                },
                handle: { crumb: { title: 'Decision Log' } },
              },
            ],
          },
        ],
      },
    ],
  },
]
