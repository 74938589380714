import { Colors } from '@blueprintjs/core'
import {
  setFTEDashboardVisibilityStatus,
  setMirroringInitiativesVisibilityStatus,
  setSharedTargetsVisibilityStatus,
} from '@generic/actions/actions'
import React from 'react'
import { CustomTooltip, ImoIcon } from '@imo/imo-ui-toolkit'
import { StyledMenuSelect } from '@myImoClient/components/TeamValueCaptureV2/MonthByMonth/components/ChartActions/ChartActions.styles'
import { get } from 'lodash/fp'
import { CONFIDENTIAL_OPTIONS_LIST, CONFIDENTIAL_TOOLTIP_TEXT } from './constants'
import { Placement } from '@shared/Popover'

export const VCControllerIDs = {
  MIRRORED_INITIATIVES: 'showMirroredInitiatives',
  FTE_DASHBOARD: 'showFTEDashboard',
  SHARED_TARGET: 'showSharedTargets',
  CONFIDENTIAL: 'initiativesConfidentialFilter',
}

const getText = get('text')
const getValue = get('value')

// TODO: `defaultValue` should be removed after refactoring useUpdateBaseData hook. This approach prevents useEffect looping
const defaultValue = {}
export const getConfidentialFilterOption = (value) => {
  return CONFIDENTIAL_OPTIONS_LIST.find((option) => option.value === value) || defaultValue
}

export const actionList = ({
  isVCTracker,
  isAdvancedFinancialsTurnedOn,
  handleChangeConfidentialFilter,
  confidentialFilter,
  hideFTEs,
}) => [
  {
    id: VCControllerIDs.MIRRORED_INITIATIVES,
    hide: !isAdvancedFinancialsTurnedOn,
    icon: (
      <CustomTooltip
        content={
          <>
            Toggle this switch to show or hide all <b>mirrored initiatives</b> in value capture dashboards for this team
          </>
        }
        key={'mirroring-initiative-visibility'}
        targetTagName={'div'}
      >
        <ImoIcon icon="mirror-out" color={Colors.GRAY2} size={16} />
      </CustomTooltip>
    ),
    label: 'Show mirrored initiatives',
    action: setMirroringInitiativesVisibilityStatus,
  },
  {
    id: VCControllerIDs.FTE_DASHBOARD,
    hide: hideFTEs,
    icon: <ImoIcon icon="people" color={Colors.GRAY2} size={16} />,
    label: 'Show FTE dashboards',
    action: setFTEDashboardVisibilityStatus,
  },
  {
    id: VCControllerIDs.SHARED_TARGET,
    hide: !isVCTracker || !isAdvancedFinancialsTurnedOn,
    icon: <ImoIcon icon="intersection" size={16} color={Colors.GRAY2} />,
    label: 'Show shared targets',
    action: setSharedTargetsVisibilityStatus,
  },
  {
    id: VCControllerIDs.CONFIDENTIAL,
    icon: (
      <CustomTooltip
        content={isVCTracker ? CONFIDENTIAL_TOOLTIP_TEXT.VC_TRACKER : CONFIDENTIAL_TOOLTIP_TEXT.TEAM_VC}
        key={'show-confidential-visibility'}
        targetTagName={'div'}
        placement={Placement.LEFT}
      >
        <ImoIcon icon="confidential" size={16} color={Colors.GRAY2} />
      </CustomTooltip>
    ),
    label: 'Show',
    customHandler: (
      <StyledMenuSelect
        key="confidential-select-type-select"
        className="confidential-select"
        selectItem={handleChangeConfidentialFilter}
        selectedItem={confidentialFilter}
        list={CONFIDENTIAL_OPTIONS_LIST}
        getOptionLabel={getText}
        getOptionValue={getValue}
        rightIcon={'caret-down'}
      />
    ),
  },
]
