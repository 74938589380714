import { createContext } from 'react'

/**
 * utility type to shallowly remove the 'readonly' marker from all properties in an object
 */
export type Writeable<T> = { -readonly [K in keyof T]: T[K] }

/**
 * represents the state of a particular feature flag for a tenant.
 */
export type FlagValue = {
  /** whether the feature flag is enabled */
  enabled: boolean
  /** if present, represents the time (UTC) that the flag value was last modified, in seconds */
  updated?: number
}

/**
 * maps all existing feature flags to a value
 * new flags are mapped as disabled with no timestamp
 */
export type TenantFeatureFlags<TFlag extends string> = { [K in TFlag]: FlagValue }

/**
 * @private factory to create a default flag value
 */
export const createFlagValue = (enabled = false): FlagValue => ({ enabled })

const defaultFeatureflags = {
  ALL_PROJECTS_SERVER_SIDE: createFlagValue(),
  PROJECT_MIRRORING: createFlagValue(true),
  TEAM_HOME_V2_LINKS: createFlagValue(true),
  DEBUG_APP: createFlagValue(),
  RESTRICTED_USER_PERMISSION_UPDATE: createFlagValue(),
  CONTROL_TOWER_NEW_ASIDE: createFlagValue(),
  FULL_PROJECT_PLAN_V2: createFlagValue(),
  IMO_BURNDOWN_V2: createFlagValue(),
  ENHANCED_DAY_ONE_GRID_FILTERS_STYLES: createFlagValue(),
  PROJECT_LIST_V2: createFlagValue(),
  AMZ_TSAV2_CUSTOM_COPY: createFlagValue(),
  SIMPLE_RISK_COLUMNS: createFlagValue(),
  ONE_TIME_BENEFIT: createFlagValue(),
  REALTIME_VC: createFlagValue(),
  FORMAT_CALENDAR_YEAR_TO_YEAR_NUMBER: createFlagValue(),
  OD_VC_INTERACTION: createFlagValue(),
  EXECUTIVE_DASHBOARD: createFlagValue(),
  VC_EXTENDED_TIMELINE: createFlagValue(),
  VC_EXTENDED_TIMELINE_DASHBOARDS: createFlagValue(),
  ASK_MYIMO_PROJECT_PLAN_CHAT: createFlagValue(),
  ASK_MYIMO_SUPPORT_BOT: createFlagValue(),
  ASK_MYIMO_DOCS: createFlagValue(),
  ASK_MYIMO_DOCS_CACHING: createFlagValue(),
  EXECUTIVE_DASHBOARD_EDITING: createFlagValue(),
  TEAM_CHARTER_HISTORY: createFlagValue(),
  NOTIFICATIONS_HUB: createFlagValue(),
  DECISION_LOG_HISTORY: createFlagValue(),
  RISK_LOG_HISTORY: createFlagValue(),
  CONTROL_TOWER_WEEKLY_STATUS_ACTIVITY_LOG: createFlagValue(),
  SMO_INITIATIVE_COMPANIES: createFlagValue(),
  VC_EXTENDED_TIMELINE_11_YEARS: createFlagValue(),
  WEEKLY_STATUS_UPDATE_DOWNLOAD: createFlagValue(),
  SMO_VC_TARGETS_BY_COMPANY: createFlagValue(),
  DATA_REQUEST_TRACKER_LOCATION: createFlagValue(),
  EXECUTIVE_DASHBOARD_V2: createFlagValue(),
  HIDE_FTE: createFlagValue(),
  CONFIDENTIAL_TEAMS: createFlagValue(),
} as const

/**
 * @private factory to create a default TenantFeatureFlags object
 * Add new keys here to create new feature flags
 */
export const createDefaultTenantFeatureFlags = () => {
  return { ...defaultFeatureflags } as Writeable<typeof defaultFeatureflags>
}

/**
 * @private a reference tenant feature flags object - used to generate types
 */
export const DEFAULT_TENANT_FEATURE_FLAGS = createDefaultTenantFeatureFlags()

export type FeatureFlag = keyof typeof DEFAULT_TENANT_FEATURE_FLAGS

export const FeatureFlagContext = createContext<TenantFeatureFlags<FeatureFlag>>(DEFAULT_TENANT_FEATURE_FLAGS)
