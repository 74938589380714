import { createSelector } from 'reselect'
import { get, getOr, flow, omit, find, filter } from 'lodash/fp'
import { pickOrderedModules } from '../utils'

export const getModuleOptionsState = get('@moduleOptions')
export const getDictionaries = flow(getModuleOptionsState, get('DICTIONARIES'))
export const getDictionaryValidation = flow(getModuleOptionsState, get('DICTIONARY_VALIDATION'))
export const getModules = flow(getModuleOptionsState, get('MODULES'))
export const getModulesSelect = flow(getModuleOptionsState, get('MODULES_SELECT'))
export const getModuleOptions = flow(getModuleOptionsState, omit('loading'))

export const getRevenueSynergiesModule = flow(getModules, find({ key: 'revenueSynergies' }))
export const getNPVModule = flow(getModules, find({ key: 'npv' }))
export const getHideFTEModule = flow(getModules, find({ key: 'hideFTE' }))
export const getAirtableIntegrationModule = flow(getModules, find({ key: 'airtableIntegration' }))
export const getAirtableModuleActive = flow(getAirtableIntegrationModule, get('active'))
export const getNWCSynergiesModules = flow(getModules, find({ key: 'NWCSynergies' }))
export const getAdvancedFinancialsModule = flow(getModules, find({ key: 'advancedFinancials' }))
export const getMirroringRestrictionsModule = flow(getModules, find({ key: 'mirroringRestrictions' }))

export const getStageGateCalculationModulesSelect = flow(getModulesSelect, find({ key: 'stageGatesCalculationType' }))
export const getCalendarViewSelect = flow(getModulesSelect, find({ key: 'calendarView' }))
export const getTargetBaseModulesSelect = flow(getModulesSelect, find({ key: 'targetBase' }))
export const getCustomNamesModulesSelect = flow(getModulesSelect, find({ key: 'initiativeColumnsCustomNames' }))
export const getNpvSettingsModulesSelect = flow(getModulesSelect, find({ key: 'npvSettings' }))
export const getVCTimelineYears = flow(getModulesSelect, find({ key: 'vcTimelineYears' }))
export const getPublishDayModulesSelect = flow(getModulesSelect, find({ key: 'publishDay' }))
export const getMirroringTeamsModulesSelect = flow(getModulesSelect, find({ key: 'mirroringTeams' }))
export const getMirroringTeamsUnselectedOptions = flow(getMirroringTeamsModulesSelect, (shape) => {
  if (!shape) return []

  const value = getOr(shape.value, ['value', 'value'], shape)

  return shape.options.filter((item: $TSFixMe) => !value.includes(item.id))
})

export const getMirroringTeamsSelectedOptions = flow(getMirroringTeamsModulesSelect, (shape) => {
  if (!shape) return []

  const value = getOr(shape.value, ['value', 'value'], shape)

  return value.reduce((acc: $TSFixMe, id: number) => {
    for (const option of shape.options) {
      if (option.id === id) acc.push(option)
    }

    return acc
  }, [])
})

export const getProjectTaskStatusDateModulesSelect = flow(getModulesSelect, find({ key: 'projectTaskStatusDate' }))
export const getInitiativeStatusDateModulesSelect = flow(getModulesSelect, find({ key: 'initiativeStatusDate' }))

export const getDictionaryProjectPlan = flow(getDictionaries, get('projectPlan'))
export const getDictionaryProjectPlanPriorities = flow(getDictionaries, get('projectPlan'), find({ name: 'priority' }))

export const getDictionaryValueCapture = flow(getDictionaries, get('valueCapture'))
export const getDictionaryTsa = flow(getDictionaries, get('tsa'))
export const getDictionaryRiskLog = flow(getDictionaries, get('riskLog'))
export const getDictionaryDecisionLog = flow(getDictionaries, get('decisionLog'))

export const getDictionaryReservedColumns = (dictionaryType: string) =>
  flow(getDictionaryValidation, get('reservedColumns'), get(dictionaryType))

export const getVisibleOrderedModules = createSelector(getModules, pickOrderedModules)

export const getIntegrations = flow(getModuleOptionsState, get('INTEGRATIONS'))
export const getAirtableIntegration = flow(getIntegrations, get('airtable'))
export const getAirtableTeamConfigurations = flow(getAirtableIntegration, get('teams'))

export const getActiveProjectPlanCustomColumns = flow(
  getDictionaryProjectPlan,
  filter(
    (dictionary: { id: number | string; isCustom: boolean; active: boolean }) =>
      dictionary.isCustom && dictionary.active && Number.isInteger(dictionary.id),
  ),
)
