import { UIMatch, useMatches } from 'react-router-dom'

export const PathKey = {
  TeamWorkspace: 'teamWorkspace',
  ControlTowerTeamWorkspace: 'controlTowerTeamWorkspace',
  WeeklyStatusUpdate: 'weeklyStatusUpdate',
} as const
export type PathKey = (typeof PathKey)[keyof typeof PathKey]

export const usePathKey = () => {
  const matches = useMatches() as Array<UIMatch<unknown, { pathKey: PathKey }>>
  const match = matches[matches.length - 1]

  return match?.handle?.pathKey
}
